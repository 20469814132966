import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfoCollectionService {

  constructor() { }

  async collectEnv(data){
    let info = {
      data:{data},
      timeOpened:new Date(),
      timezone:(new Date()).getTimezoneOffset()/60,

      pageOn: this.pageon(),
      referrer: this.referrer(),
      previousSite: this.previousSites(),
      browserName: this.browserName(),
      browserEngine: this.browserEngine(),
      browserVersion1a: this.browserVersion1a(),
      browserVersion1b: this.browserVersion1b(),
      browserOnline: this.browserOnline(),
      browserPlatform: this.browserPlatform(),
      
      //userinfo
      dataStorage: this.dataStorage(),

      //batery info
      battery: await this.getBattery().then((x)=>{return x}),
    }
    console.log(info);
    return info;
  }

  pageon(){return window.location.href};
  referrer(){return document.referrer};
  previousSites(){return history.length};

  browserName(){return navigator.appName};
      
  browserEngine(){return navigator.product};
  browserVersion1a(){return navigator.appVersion};
  browserVersion1b(){return navigator.userAgent};
  browserLanguage(){return navigator.language};
  browserOnline(){return navigator.onLine};
  browserPlatform(){return navigator.platform};
  javaEnabled(){return navigator.javaEnabled()};
  dataCookiesEnabled(){return navigator.cookieEnabled};
  dataCookies1(){return document.cookie};
  dataCookies2(){return document.cookie.split(";")};
  dataStorage(){return localStorage};

  sizeScreenW(){return screen.width};
  sizeScreenH(){return screen.height};
  // sizeDocW(){return document.width},
  // sizeDocH(){return document.height},
  sizeInW(){return innerWidth};
  sizeInH(){return innerHeight};
  sizeAvailW(){return screen.availWidth};
  sizeAvailH(){return screen.availHeight};
  scrColorDepth(){return screen.colorDepth};
  scrPixelDepth(){return screen.pixelDepth};

      //ubicacion
      /* latitude(){return position.coords.latitude},
      longitude(){return position.coords.longitude},
      accuracy(){return position.coords.accuracy},
      altitude(){return position.coords.altitude},
      altitudeAccuracy(){return position.coords.altitudeAccuracy},
      heading(){return position.coords.heading},
      speed(){return position.coords.speed},
      timestamp(){return position.timestamp}, */

  //bateria
  async getBattery(){
    let nav:any = navigator;
    if (nav.getBattery == undefined) {
      return 'N/A';
    } else {
      return nav.getBattery().then((x)=>{
        return x;
      });
    }
    
    
    
  }
}
