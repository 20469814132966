import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FileUploaderComponent } from './file-uploader.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    FileUploaderComponent,
  ],
  exports: [
    FileUploaderComponent,
  ],
  imports:[
    CommonModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class FileUploaderComponentModule { }