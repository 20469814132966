<div class="overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
  <div class="p-6">

    <section class="w-full divide-y rounded divide-slate-200 ">
      <details class="group" close>

        <summary class="[&::-webkit-details-marker]:hidden relative font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 group-hover:text-slate-900 ">
          <h3 class="mb-4 text-md font-medium text-slate-700">Ingresar su comentario</h3>
          <svg xmlns="http://www.w3.org/2000/svg" class="absolute right-0 w-4 h-4 transition duration-300 top-1 stroke-slate-700 shrink-0 group-open:rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5" aria-labelledby="title-ac01 desc-ac01">
            <title id="title-ac01">Open icon</title>
            <desc id="desc-ac01">icon that represents the state of the summary</desc>
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
          </svg>
        </summary>

        <div class="my-3">

          <!-- Component: Rounded large size textarea with helper text-->
          <div class="relative">
            <textarea [(ngModel)]="newComment.body" id="id-l03" type="text" name="id-l03" rows="10" placeholder="Comentario" class="relative w-full p-4 placeholder-transparent transition-all border rounded outline-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"></textarea>
            <label for="id-l03" class="cursor-text peer-focus:cursor-default absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent">
              Comentario
            </label>
            
          </div>
          <!-- End Rounded large size textarea with helper text -->

          <div class="flex">
            <div class="flex-1">
              <button class="mr-3 my-3 inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide 
              text-white transition duration-300 rounded-full focus-visible:outline-none whitespace-nowrap bg-sky-500 hover:bg-sky-600 
              focus:bg-sky-700 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-sky-300 disabled:shadow-none"
              (click)="popFileChooser()">
                <span>
                  <svg class="w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 17L12 10M12 10L15 13M12 10L9 13" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 7H12H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#fff" stroke-width="1.5"/>
                  </svg>
                </span>
                <span>Adjuntar</span>
              </button>
            </div>
            <div class="flex-initial">
              <button class="mr-3 my-3 inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide 
              text-white transition duration-300 rounded-full focus-visible:outline-none whitespace-nowrap bg-green-500 hover:bg-green-600 
              focus:bg-green-700 disabled:cursor-not-allowed disabled:border-green-300 disabled:bg-green-300 disabled:shadow-none"
              (click)="createComment()">
                <span>Publicar</span>
              </button>

              <input type="file" multiple #uploader hidden (change)="ListarDocumentos($event, 'attached_files')">
            </div>
            

            

          </div>
          <div *ngIf="filesToBeLoad.length != 0">
            <p class="text-md font-semibold">Archivos cargados</p>

            <div class="grid grid-cols-4 md:grid-cols-10 gap-4" >
              <div class="" *ngFor="let file of filesToBeLoad; let i = index" >
                <div class="has-tooltip" (click)="retirarDocumento(i)">
                  <svg class="w-full " viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier"> <g opacity="0.5"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V10C2 6.22876 2 4.34315 3.17157 3.17157C4.34315 2 6.23869 2 10.0298 2C10.6358 2 11.1214 2 11.53 2.01666C11.5166 2.09659 11.5095 2.17813 11.5092 2.26057L11.5 5.09497C11.4999 6.19207 11.4998 7.16164 11.6049 7.94316C11.7188 8.79028 11.9803 9.63726 12.6716 10.3285C13.3628 11.0198 14.2098 11.2813 15.0569 11.3952C15.8385 11.5003 16.808 11.5002 17.9051 11.5001L18 11.5001H21.9574C22 12.0344 22 12.6901 22 13.5629V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22Z" fill="#409ab0"></path> </g> 
                    <path d="M6 13.75C5.58579 13.75 5.25 14.0858 5.25 14.5C5.25 14.9142 5.58579 15.25 6 15.25H14C14.4142 15.25 14.75 14.9142 14.75 14.5C14.75 14.0858 14.4142 13.75 14 13.75H6Z" fill="#409ab0"></path> <path d="M6 17.25C5.58579 17.25 5.25 17.5858 5.25 18C5.25 18.4142 5.58579 18.75 6 18.75H11.5C11.9142 18.75 12.25 18.4142 12.25 18C12.25 17.5858 11.9142 17.25 11.5 17.25H6Z" fill="#409ab0"></path> 
                    <path d="M11.5092 2.2601L11.5 5.0945C11.4999 6.1916 11.4998 7.16117 11.6049 7.94269C11.7188 8.78981 11.9803 9.6368 12.6716 10.3281C13.3629 11.0193 14.2098 11.2808 15.057 11.3947C15.8385 11.4998 16.808 11.4997 17.9051 11.4996L21.9574 11.4996C21.9698 11.6552 21.9786 11.821 21.9848 11.9995H22C22 11.732 22 11.5983 21.9901 11.4408C21.9335 10.5463 21.5617 9.52125 21.0315 8.79853C20.9382 8.6713 20.8743 8.59493 20.7467 8.44218C19.9542 7.49359 18.911 6.31193 18 5.49953C17.1892 4.77645 16.0787 3.98536 15.1101 3.3385C14.2781 2.78275 13.862 2.50487 13.2915 2.29834C13.1403 2.24359 12.9408 2.18311 12.7846 2.14466C12.4006 2.05013 12.0268 2.01725 11.5 2.00586L11.5092 2.2601Z" fill="#409ab0"></path> </g>
                  </svg>
                  <span class='tooltip rounded shadow-lg p-1 bg-gray-100'>{{file.name}} - {{file.type}}</span>
                </div>
                
                
                <p class="line-clamp-2">
                  {{file.name}} 
                </p>                
              </div>
            </div>
          </div>
        </div>
      </details>
    </section>
  </div>
</div>



<h2 class="my-3 font-bold text-2xl">Muro de actividad - comentarios</h2>

<!-- Component: User feed -->

<p class="font-semibold text-xl" *ngIf="comments.length == 0">No existen comentarios - notas</p>

<ul *ngIf="comments.length != 0" aria-label="User feed" role="feed" class="relative flex flex-col gap-12 py-4 pl-6 before:absolute before:top-0 
  before:left-6 before:h-full before:border before:-translate-x-1/2 before:border-slate-200 before:border-dashed after:absolute after:top-6 
  after:left-6 after:bottom-6 after:border after:-translate-x-1/2 after:border-slate-200 ">

  <div class="">
    <li role="article" class="relative pl-6 mb-5" *ngFor="let comment of comments">
      <div class="flex flex-col flex-1 gap-2">
        <a href="#" class="absolute z-10 inline-flex items-center justify-center w-6 h-6 text-white rounded-full -left-3 ring-2 ring-white">
          <img [src]="'https://ui-avatars.com/api/?background=random&name='+comment.attributes.users_permissions_user.data.attributes.name+'+'+comment.attributes.users_permissions_user.data.attributes.lastname" alt="user name" title="user name" width="48" height="48" class="max-w-full rounded-full" />
        </a>
        <h4 class="flex flex-col items-start text-base font-medium leading-6 lg:items-center md:flex-row text-slate-700">
          <span class="flex-1">{{comment.attributes.users_permissions_user.data.attributes.name}} {{comment.attributes.users_permissions_user.data.attributes.lastname}}
            <span class="text-sm font-normal text-slate-500"> - agrego un nuevo comentario</span>
          </span>
          <span class="text-xs font-normal text-slate-400"> Públicado a las {{comment.attributes.createdAt}}</span>
          <span *ngIf="comment.attributes.users_permissions_user.data.id == user_id">
            <!-- Component: Small primary button with icon  -->
            <button (click)="ClickOptionMenu(comment.id)" class="inline-flex items-center self-center justify-center h-4 gap-2 ml-4 px-4 text-xs font-medium tracking-wide text-white transition duration-300 rounded-full focus-visible:outline-none whitespace-nowrap bg-sky-500 hover:bg-sky-600 focus:bg-sky-700 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-sky-300 disabled:shadow-none">
              <span class="relative only:-mx-4">
                <span class="sr-only">Opciones</span>
                <svg class="w-3 h-3" fill="#fff" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M28.106 19.944h-0.85c-0.069-0.019-0.131-0.050-0.2-0.063-1.788-0.275-3.2-1.762-3.319-3.506-0.137-1.95 0.975-3.6 2.787-4.137 0.238-0.069 0.488-0.119 0.731-0.181h0.85c0.056 0.019 0.106 0.050 0.169 0.056 1.65 0.269 2.906 1.456 3.262 3.081 0.025 0.125 0.063 0.25 0.094 0.375v0.85c-0.019 0.056-0.050 0.113-0.056 0.169-0.262 1.625-1.419 2.863-3.025 3.238-0.156 0.038-0.3 0.081-0.444 0.119zM4.081 12.056l0.85 0c0.069 0.019 0.131 0.050 0.2 0.056 1.8 0.281 3.206 1.775 3.319 3.537 0.125 1.944-1 3.588-2.819 4.119-0.231 0.069-0.469 0.119-0.7 0.175h-0.85c-0.056-0.019-0.106-0.050-0.162-0.063-1.625-0.3-2.688-1.244-3.194-2.819-0.069-0.206-0.106-0.425-0.162-0.637v-0.85c0.019-0.056 0.050-0.113 0.056-0.169 0.269-1.631 1.419-2.863 3.025-3.238 0.15-0.037 0.294-0.075 0.437-0.113zM15.669 12.056h0.85c0.069 0.019 0.131 0.050 0.2 0.063 1.794 0.281 3.238 1.831 3.313 3.581 0.087 1.969-1.1 3.637-2.931 4.106-0.194 0.050-0.387 0.094-0.581 0.137h-0.85c-0.069-0.019-0.131-0.050-0.2-0.063-1.794-0.275-3.238-1.831-3.319-3.581-0.094-1.969 1.1-3.637 2.931-4.106 0.2-0.050 0.394-0.094 0.588-0.137z"></path> </g></svg>
              </span>
            </button>
            <!-- End Small primary button with icon  -->
          </span>
        </h4>

        <div class="overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200">
          <div class="p-4">
            <div>
              <p class="text-sm text-slate-500" style="white-space: break-spaces;">{{comment.attributes.comment_body}}</p>
            </div>
            <div class="my-2">
              <div *ngIf="comment.attributes.attached_files.data != null">
                <p class="text-sm font-semibold">Archivos cargados</p>
    
                <div class="grid grid-cols-4 md:grid-cols-12 gap-4" >
                  <div class="" *ngFor="let file of comment.attributes.attached_files.data; let i = index" >
                    <div class="has-tooltip" (click)="clickAttachment(file.attributes.url)">
                      <svg class="w-full " viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier"> <g opacity="0.5"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V10C2 6.22876 2 4.34315 3.17157 3.17157C4.34315 2 6.23869 2 10.0298 2C10.6358 2 11.1214 2 11.53 2.01666C11.5166 2.09659 11.5095 2.17813 11.5092 2.26057L11.5 5.09497C11.4999 6.19207 11.4998 7.16164 11.6049 7.94316C11.7188 8.79028 11.9803 9.63726 12.6716 10.3285C13.3628 11.0198 14.2098 11.2813 15.0569 11.3952C15.8385 11.5003 16.808 11.5002 17.9051 11.5001L18 11.5001H21.9574C22 12.0344 22 12.6901 22 13.5629V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22Z" fill="#409ab0"></path> </g> 
                        <path d="M6 13.75C5.58579 13.75 5.25 14.0858 5.25 14.5C5.25 14.9142 5.58579 15.25 6 15.25H14C14.4142 15.25 14.75 14.9142 14.75 14.5C14.75 14.0858 14.4142 13.75 14 13.75H6Z" fill="#409ab0"></path> <path d="M6 17.25C5.58579 17.25 5.25 17.5858 5.25 18C5.25 18.4142 5.58579 18.75 6 18.75H11.5C11.9142 18.75 12.25 18.4142 12.25 18C12.25 17.5858 11.9142 17.25 11.5 17.25H6Z" fill="#409ab0"></path> 
                        <path d="M11.5092 2.2601L11.5 5.0945C11.4999 6.1916 11.4998 7.16117 11.6049 7.94269C11.7188 8.78981 11.9803 9.6368 12.6716 10.3281C13.3629 11.0193 14.2098 11.2808 15.057 11.3947C15.8385 11.4998 16.808 11.4997 17.9051 11.4996L21.9574 11.4996C21.9698 11.6552 21.9786 11.821 21.9848 11.9995H22C22 11.732 22 11.5983 21.9901 11.4408C21.9335 10.5463 21.5617 9.52125 21.0315 8.79853C20.9382 8.6713 20.8743 8.59493 20.7467 8.44218C19.9542 7.49359 18.911 6.31193 18 5.49953C17.1892 4.77645 16.0787 3.98536 15.1101 3.3385C14.2781 2.78275 13.862 2.50487 13.2915 2.29834C13.1403 2.24359 12.9408 2.18311 12.7846 2.14466C12.4006 2.05013 12.0268 2.01725 11.5 2.00586L11.5092 2.2601Z" fill="#409ab0"></path> </g>
                      </svg>
                      <span class='tooltip rounded shadow-lg p-1 bg-gray-100'>{{file.attributes.name}} - {{file.attributes.mime}}</span>
                    </div>
                    
                    
                    <p class="line-clamp-2">
                      {{file.attributes.name}} 
                    </p>                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </div>
  
</ul>
<!-- End User feed -->