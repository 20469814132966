import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManagementDataService {

  constructor(private http: HttpClient, private auth:AuthService) { 
  
  }

  request(module:string,request:string):any{
    let user = this.auth.whoami();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.jwt}`);

    return this.http.get(`${environment.strapi_server}/${module}${request}`,{headers});
  }

  index(module:string):any{
    let user = this.auth.whoami();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.jwt}`);

    return this.http.get(`${environment.strapi_server}/${module}?populate=*&sort=id`,{headers});
  }

  create(module:string,data:any){
    let user = this.auth.whoami();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.jwt}`);

    return this.http.post(`${environment.strapi_server}/${module}`,{data:data},{headers});
  }

  details(module:string,id:string){
    let user = this.auth.whoami();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.jwt}`);

    return this.http.get(`${environment.strapi_server}/${module}/${id}?populate=*`,{headers});
  }

  update(module:string,data:any, id:number){
    let user = this.auth.whoami();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.jwt}`);

    return this.http.put(`${environment.strapi_server}/${module}/${id}`,{data:data},{headers});
  }
  
  delete(module:string, id:number){
    let user = this.auth.whoami();
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.jwt}`);

    return this.http.delete(`${environment.strapi_server}/${module}/${id}`,{headers});
  }
}
