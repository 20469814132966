import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu-principal',
    pathMatch: 'full'
  },
  {
    path: 'menu-principal',
    loadChildren: () => import('./modules/menus/menu-principal/menu-principal.module').then( m => m.MenuPrincipalPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/login/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/auth/profile/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'clientes',
    loadChildren: () => import('./modules/clientes/clientes.module').then( m => m.ClientesModule)
  },
  {
    path: 'control-vehiculos',
    loadChildren: () => import('./modules/vehiculos/vehicules.module').then( m => m.VehiculesModule)
  },
  {
    path: 'modal-brand',
    loadChildren: () => import('./modules/misc/modals/modal-brand/modal-brand.module').then( m => m.ModalBrandPageModule)
  },
  {
    path: 'modal-vehicule',
    loadChildren: () => import('./modules/misc/modals/modal-vehicule/modal-vehicule.module').then( m => m.ModalVehiculePageModule)
  },
  {
    path: 'modal-customer',
    loadChildren: () => import('./modules/misc/modals/modal-customer/modal-customer.module').then( m => m.ModalCustomerPageModule)
  },
  {
    path: 'citas',
    loadChildren: () => import('./modules/citas/appointments-index/appointments-index.module').then( m => m.AppointmentsIndexPageModule)
  },
  {
    path: 'citas/new',
    loadChildren: () => import('./modules/citas/appointments-new/appointments-new.module').then( m => m.AppointmentsNewPageModule)
  },
  {
    path: 'citas/:id',
    loadChildren: () => import('./modules/citas/appointments-detail/appointments-detail.module').then( m => m.AppointmentsDetailPageModule)
  },
  {
    path: 'citas/:id/editar',
    loadChildren: () => import('./modules/citas/appointments-edit/appointments-edit.module').then( m => m.AppointmentsEditPageModule)
  },
  {
    path: 'cotizaciones',
    loadChildren: () => import('./modules/cotizaciones/quotes-index/quotes-index.module').then( m => m.QuotesIndexPageModule)
  },
  {
    path: 'pagos',
    loadChildren: () => import('./modules/facturacion/payments-index/payments-index.module').then( m => m.PaymentsIndexPageModule)
  },
  {
    path: 'workorders',
    loadChildren: () => import('./modules/ordenes-trabajo/workorder-index/workorder-index.module').then( m => m.WorkorderIndexPageModule)
  },
  {
    path: 'workorders/new',
    loadChildren: () => import('./modules/ordenes-trabajo/workorder-new/workorder-new.module').then( m => m.WorkorderNewPageModule)
  },
  {
    path: 'workorders/:id',
    loadChildren: () => import('./modules/ordenes-trabajo/workorder-detail/workorder-detail.module').then( m => m.WorkorderDetailPageModule)
  },
  {
    path: 'workorders/:id/editar',
    loadChildren: () => import('./modules/ordenes-trabajo/workorder-edit/workorder-edit.module').then( m => m.WorkorderEditPageModule)
  },
  {
    path: 'recepciones',
    loadChildren: () => import('./modules/recepcion/receptions-index/receptions-index.module').then( m => m.ReceptionsIndexPageModule)
  },
  {
    path: 'recepciones/new',
    loadChildren: () => import('./modules/recepcion/receptions-new/receptions-new.module').then( m => m.ReceptionsNewPageModule)
  },
  {
    path: 'recepciones/:id',
    loadChildren: () => import('./modules/recepcion/receptions-detail/receptions-detail.module').then( m => m.ReceptionsDetailPageModule)
  },
  {
    path: 'recepciones/:id/editar',
    loadChildren: () => import('./modules/recepcion/receptions-edit/receptions-edit.module').then( m => m.ReceptionsEditPageModule)
  },
  {
    path: 'recepciones/:id/firmar',
    loadChildren: () => import('./modules/recepcion/recptions-signature-terms/recptions-signature-terms.module').then( m => m.RecptionsSignatureTermsPageModule)
  },
  {
    path: 'recepciones/:id/completar',
    loadChildren: () => import('./modules/recepcion/receptions-complete/receptions-complete.module').then( m => m.ReceptionsCompletePageModule)
  },
  
  {
    path: 'insurance-jobs',
    loadChildren: () => import('./modules/trabajos-aseguradora/insurance-jobs-index/insurance-jobs-index.module').then( m => m.InsuranceJobsIndexPageModule)
  },
  {
    path: 'insurance-jobs/new',
    loadChildren: () => import('./modules/trabajos-aseguradora/insurance-jobs-new/insurance-jobs-new.module').then( m => m.InsuranceJobsNewPageModule)
  },
  {
    path: 'insurance-jobs/:id',
    loadChildren: () => import('./modules/trabajos-aseguradora/insurance-jobs-detail/insurance-jobs-detail.module').then( m => m.InsuranceJobsDetailPageModule)
  },
  {
    path: 'insurance-jobs/:id/editar',
    loadChildren: () => import('./modules/trabajos-aseguradora/insurance-jobs-edit/insurance-jobs-edit.module').then( m => m.InsuranceJobsEditPageModule)
  },
  {
    path: 'admin/company-info',
    loadChildren: () => import('./modules/infoCompania/company-info-index/company-info-index.module').then( m => m.CompanyInfoIndexPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/misc/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'admin/warranty-certificates',
    loadChildren: () => import('./modules/certificadosGarantia/warranty-certificates/warranty-certificates.module').then( m => m.WarrantyCertificatesPageModule)
  },
  {
    path: 'admin/terms-and-cond',
    loadChildren: () => import('./modules/admin/terminosCondiciones/terms-and-cond-control/terms-and-cond-control.module').then( m => m.TermsAndCondControlPageModule)
  },
  {
    path: 'admin/menu',
    loadChildren: () => import('./modules/menus/admin-menu/admin-menu.module').then( m => m.AdminMenuPageModule)
  },
  {
    path: 'modal-user',
    loadChildren: () => import('./modules/misc/modals/modal-user/modal-user.module').then( m => m.ModalUserPageModule)
  },
  {
    path: 'general-modal',
    loadChildren: () => import('./modules/misc/modals/general-modal/general-modal.module').then( m => m.GeneralModalPageModule)
  },
  {
    path: 'admin/modules',
    loadChildren: () => import('./modules/admin/moduleControl/module-control-index/module-control-index.module').then( m => m.ModuleControlIndexPageModule)
  },
  {
    path: 'admin/modules/:id',
    loadChildren: () => import('./modules/admin/moduleControl/module-control-detail/module-control-detail.module').then( m => m.ModuleControlDetailPageModule)
  },
  {
    path: 'admin/alerts',
    loadChildren: () => import('./modules/admin/alerts/alerts-index/alerts-index.module').then( m => m.AlertsIndexPageModule)
  },
  {
    path: 'admin/alerts/new',
    loadChildren: () => import('./modules/admin/alerts/alerts-new/alerts-new.module').then( m => m.AlertsNewPageModule)
  },
  {
    path: 'admin/alerts/:id',
    loadChildren: () => import('./modules/admin/alerts/alerts-detail/alerts-detail.module').then( m => m.AlertsDetailPageModule)
  },
  {
    path: 'admin/alerts/:id/editar',
    loadChildren: () => import('./modules/admin/alerts/alerts-edit/alerts-edit.module').then( m => m.AlertsEditPageModule)
  },
  {
    path: 'admin/users',
    loadChildren: () => import('./modules/admin/user-control/users/users-index/users-index.module').then( m => m.UsersIndexPageModule)
  },
  {
    path: 'admin/users/new',
    loadChildren: () => import('./modules/admin/user-control/users/users-new/users-new.module').then( m => m.UsersNewPageModule)
  },
  {
    path: 'admin/users/:id',
    loadChildren: () => import('./modules/admin/user-control/users/users-detail/users-detail.module').then( m => m.UsersDetailPageModule)
  },
  {
    path: 'admin/users/:id/editar',
    loadChildren: () => import('./modules/admin/user-control/users/users-edit/users-edit.module').then( m => m.UsersEditPageModule)
  },
  {
    path: 'admin/user-roles',
    loadChildren: () => import('./modules/admin/user-control/roles/user-roles-index/user-roles-index.module').then( m => m.UserRolesIndexPageModule)
  },
  {
    path: 'admin/user-roles/new',
    loadChildren: () => import('./modules/admin/user-control/roles/user-roles-new/user-roles-new.module').then( m => m.UserRolesNewPageModule)
  },
  {
    path: 'admin/user-roles/:id',
    loadChildren: () => import('./modules/admin/user-control/roles/user-roles-detail/user-roles-detail.module').then( m => m.UserRolesDetailPageModule)
  },
  {
    path: 'admin/user-roles/:id/editar',
    loadChildren: () => import('./modules/admin/user-control/roles/user-roles-edit/user-roles-edit.module').then( m => m.UserRolesEditPageModule)
  },
  {
    path: 'reception-upload-modal',
    loadChildren: () => import('./modules/misc/modals/reception-upload-modal/reception-upload-modal.module').then( m => m.ReceptionUploadModalPageModule)
  },
  {
    path: 'inventarios',
    loadChildren: () => import('./modules/inventario/inventory-index/inventory-index.module').then( m => m.InventoryIndexPageModule)
  },
  {
    path: 'inventarios/productos',
    loadChildren: () => import('./modules/inventario/products/products-index/products-index.module').then( m => m.ProductsIndexPageModule)
  },
  {
    path: 'inventarios/productos/new',
    loadChildren: () => import('./modules/inventario/products/products-new/products-new.module').then( m => m.ProductsNewPageModule)
  },
  {
    path: 'inventarios/productos/:id/editar',
    loadChildren: () => import('./modules/inventario/products/products-edit/products-edit.module').then( m => m.ProductsEditPageModule)
  },
  {
    path: 'inventarios/productos/:id',
    loadChildren: () => import('./modules/inventario/products/products-detail/products-detail.module').then( m => m.ProductsDetailPageModule)
  },
  
  {
    path: 'inventarios/warehouses',
    loadChildren: () => import('./modules/inventario/warehouse/warehouses-index/warehouses-index.module').then( m => m.WarehousesIndexPageModule)
  },
  {
    path: 'inventarios/warehouses/new',
    loadChildren: () => import('./modules/inventario/warehouse/warehouses-new/warehouses-new.module').then( m => m.WarehousesNewPageModule)
  },
  {
    path: 'inventarios/warehouses/:id',
    loadChildren: () => import('./modules/inventario/warehouse/warehouses-detail/warehouses-detail.module').then( m => m.WarehousesDetailPageModule)
  },
  {
    path: 'inventarios/warehouses/:id/editar',
    loadChildren: () => import('./modules/inventario/warehouse/warehouses-edit/warehouses-edit.module').then( m => m.WarehousesEditPageModule)
  },
  {
    path: 'new-record-modal',
    loadChildren: () => import('./modules/misc/modals/new-record-modal/new-record-modal.module').then( m => m.NewRecordModalPageModule)
  },
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
