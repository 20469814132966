import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

    constructor(private http: HttpClient, private auth:AuthService) {}

    uploadFile(file:FormData){
        return this.http.post(`${environment.strapi_server}/upload`, file, {
            reportProgress: true,
            observe: 'events'
        })
    }
    getFile(){}

    deleteFile(id){

        let user = this.auth.whoami();
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${user.jwt}`);

        return this.http.delete(`${environment.strapi_server}/upload/files/${id}`, {headers});
    }  

}