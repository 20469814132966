import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class AlertsManagementServiceService {

  constructor(private alertController: AlertController, private navCtrl: NavController) { }

  async mostrarAlertError(){
    let error_alert = await this.alertController.create({
      header: 'No se logró validar la acción',
      subHeader: `Ocurrio un evento inesperado`,
      message: `No se pudo obtener los detalles solicitados. Intente de nuevo.`,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {  
            this.navCtrl.back();
          }
        }
      ],
    });
    await error_alert.present(); 
  }

  async mostrarAlertCompletado(){
    let error_alert = await this.alertController.create({
      header: 'Acción completada',
      subHeader: `Evento fue completado correctamente`,
      message: `La acción solicitada fue completada correctamente.`,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {  
            console.info('click');
          }
        }
      ],
    });
    await error_alert.present(); 
  }
}
