import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommentComponent } from './comment.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CommentComponent,
    
  ],
  exports: [
    CommentComponent,
  ],
  imports:[
    CommonModule,
    FormsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class CommentComponentModule { }