import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ManagementDataService } from './management-data.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private dataMgmt: ManagementDataService, private auth:AuthService) { 
  
  }

  addComment(comment){

    comment.users_permissions_user = this.auth.whoami().user.id;
    console.log(comment);
    return this.dataMgmt.create('comments', comment);
  }

  removeComment(comment_id){
    return this.dataMgmt.delete('comments',comment_id);
  }

  getAllCommentsOnModule(module,parent_record_id){
    return this.dataMgmt.request('comments',`?filters[record_id][$eq]=${parent_record_id}&filters[module_name][$eq]=${module}&populate[users_permissions_user][fields][1]=name&populate[users_permissions_user][fields][2]=lastname&populate[users_permissions_user][fields][3]=username&populate[users_permissions_user][fields][4]=email&populate[users_permissions_user][fields][5]=id&populate[attached_files][fields][0]=url&populate[attached_files][fields][1]=name&populate[attached_files][fields][2]=mime&sort=createdAt:desc`);
  }

}
