import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router:Router) { }

  login(email:string,password:string){
    return this.http.post(`${environment.strapi_server}/auth/local`,{
      identifier:email, password:password
    });
  }

  logout(){
    localStorage.removeItem('user');
    this.router.navigateByUrl('/login').then(()=>{
      location.reload();
    });
    
  }

  whoami(){
    if(localStorage.getItem('user') == null){
      this.router.navigateByUrl('/login');
    }else{
      try {
        return JSON.parse(atob(localStorage.getItem('user')) || '{}');
      } catch (error) {
        console.log(error);
        this.logout();
      }
      
    }
  }

  isUserLoggedIn(){
    if(localStorage.getItem('user') == null){
      return false;
    }else{
      return true;
    }
  }

  getUserDetail(){

    let jwtToken = this.whoami().jwt || {};
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${jwtToken}`);

    return this.http.get(`${environment.strapi_server}/users/me?populate=*`,{headers});
  }

  async validateUserRole(){
    let jwtToken = this.whoami().jwt || {};
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${jwtToken}`);

    this.http.get(`${environment.strapi_server}/users/me?populate[role]=*`,{headers}).subscribe((user_detail:any)=>{
      this.http.get(`${environment.strapi_server}/users-permissions/roles/${user_detail.role.id}?populate=*`,{headers}).subscribe((role:any)=>{
        console.log(role);

        return role;
      });
    },((error)=>{
      return error;
    }));
  }

  createNewUser(data:any){
    let jwtToken = this.whoami().jwt || {};
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${jwtToken}`);

    return this.http.post(`${environment.strapi_server}/users`,data,{headers});
  }

  editUser(id:number,data:any){
    let jwtToken = this.whoami().jwt || {};
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${jwtToken}`);

    return this.http.put(`${environment.strapi_server}/users/${id}`,data,{headers});
  }

  getUser(id){

    let jwtToken = this.whoami().jwt || {};
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${jwtToken}`);

    return this.http.get(`${environment.strapi_server}/users/${id}?populate=*`,{headers});
  }
}
