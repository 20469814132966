<ion-app>
  <ion-split-pane contentId="main-content" >
    <ion-menu contentId="main-content" type="overlay" *ngIf="userLoggedIn" style="
    border-right: 1px #c8c7cc solid;">
      <ion-content>
        <ion-list id="general-list">
          <ion-list-header>Menú</ion-list-header>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list id="misc-list">
          <ion-list-header>Misc</ion-list-header>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of miscPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list id="admin-list">
          <ion-list-header>Admin Menú</ion-list-header>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of adminPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        
      </ion-content>
      <ion-footer>
        <ion-card class="ion-text-left" >
          <ion-grid>
            <ion-row>
              <ion-col size="10" sizeLg="9" routerLink="/profile">
                <ion-card-content>
                  <ion-card-subtitle class="flex">    
                    <div class="visible lg:invisible xl:visible mt-2 2xl:mt-3">
                      <img alt="cover picture" [src]="'https://ui-avatars.com/api/?size=128&background=random&name='+currentUser.user?.name+'+'+currentUser.user?.lastname" class="rounded-full w-10"/>
                    </div>     
                    <div class="ml-3 mt-5 lg:mt-5 lg:ml-1 md:mt-5 xl:ml-1 2xl:mt-6 2xl:ml-3">
                      {{currentUser.user.name}} {{currentUser.user.lastname}}
                    </div> 
                  </ion-card-subtitle>
                </ion-card-content>
                
              </ion-col>
              <ion-col size="2" sizeLg="3">
                <ion-button size="medium" fill="clear" class="ion-no-padding" (click)="logout()">
                  <ion-icon name="exit-outline"></ion-icon>
                </ion-button>                
              </ion-col>
            </ion-row>
          </ion-grid>
          
        </ion-card>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

    
  </ion-split-pane>
</ion-app>
