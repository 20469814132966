import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UploadFileService } from 'src/app/services/upload_files.service';
import { environment } from 'src/environments/environment';
import { AlertController, LoadingController,} from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { ManagementDataService } from 'src/app/services/management-data.service';
import { AlertsManagementServiceService } from 'src/app/services/alerts-management-service.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent  implements OnInit {

  @Input() module;
  @Input() record_id;
  @Input() field;
  @Input() apiModule; 

  @ViewChild("uploader") uploader: ElementRef<HTMLInputElement>;

  fileList:any[] = [];

  constructor(private datamgt: ManagementDataService, private alertController: AlertController, private fileServ:UploadFileService,
    private loadingCtrl: LoadingController, private route:ActivatedRoute, private alertServ: AlertsManagementServiceService
  ) { }

  ngOnInit() {
    this.obtenerArchivosCargados();
  }

  clickAttachment(url:string){
    window.open(url);
    console.info('url opened', url)
  }

  popFileChooser(): void {
    this.uploader.nativeElement.click();
  }

  async cargarDocumentos(event){
      console.log(event);
  
      let loading = await this.loadingCtrl.create({
        message: 'Cargando documentos',
        spinner: 'dots',
        translucent: true
      });
      await loading.present();
  
      for (let item = 0; item < event.target.files.length; item++) {
        const current_file = event.target.files[item];
        
        let file:File = current_file;
        let formData:FormData = new FormData();
  
        
  
        formData.append("files",file);
        formData.append("path",`${environment.instance_name}/${this.module}/${this.record_id}`)
        formData.append("ref",`api::${this.apiModule}`);
        formData.append("refId",`${this.record_id}`);
        formData.append("field",`${this.field}`);
  
        await this.fileServ.uploadFile(formData).toPromise()
        .then(async (data)=>{
          console.log(data);
          console.log(
            data.type == 1 ? (data.loaded / data.total) * 100 : "Cargando"
          );
          if (data.type == 0) {
            loading.message = "Iniciando carga de documento..."
          }
          if (data.type == 1) {
            loading.message = `Cargando archivo ${Math.round((data.loaded / data.total) * 100)}% ...` 
          }
          if (data.type == 3) {
            loading.message = `Completando carga de archivo...` 
          }
        })
        .catch(async (err) => {
          console.log(err);
          let error_alert = await this.alertController.create({
            header: 'No se logró cargar el documento',
            subHeader: ``,
            message: `No se pudo cargar el documento`,
            buttons: ['OK'],
          });
          loading.dismiss();
          await error_alert.present();
        })
        .finally(()=>{
          loading.dismiss();
          this.obtenerArchivosCargados();
        })
      }
  
  }

  async obtenerArchivosCargados(){
    let loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'dots',
      translucent: true
    });
    await loading.present();

    this.datamgt.request(this.module,`/${this.record_id}?populate[0]=${this.field}`).subscribe({
      next: (data) => {
        this.fileList = data.data.attributes[this.field].data;
      },
      error: async (err) => {
        console.log(err);
        this.alertServ.mostrarAlertError();
        loading.dismiss();
        
      },complete:() => {
        
        loading.dismiss();
      }
    })
  }
}
