<div>
  <div class="flex my-4">
    <div class="flex-1 items-center">
      <h1 class="text-2xl">Documentos</h1>
    </div>
    <div class="flex-initial">
      <button class="mr-3 my-3 inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide 
              text-white transition duration-300 rounded-full focus-visible:outline-none whitespace-nowrap bg-sky-500 hover:bg-sky-600 
              focus:bg-sky-700 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-sky-300 disabled:shadow-none"
              (click)="popFileChooser()">
        <span>
          <svg class="w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 17L12 10M12 10L15 13M12 10L9 13" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 7H12H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#fff" stroke-width="1.5"/>
          </svg>
        </span>
        <span>Adjuntar</span>
      </button>
    </div>
    <div>
      <input type="file" multiple #uploader hidden (change)="cargarDocumentos($event)">
    </div>
  </div>
  <div class="my-4">
    <div *ngIf="fileList != null">
      <p class="text-md font-semibold">Archivos cargados</p>

      <div class="grid grid-cols-4 md:grid-cols-10 gap-4" >
        <div class="" *ngFor="let file of fileList; let i = index" >
          <div class="has-tooltip" (click)="clickAttachment(file.attributes.url)">
            <svg class="w-full " viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier"> <g opacity="0.5"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V10C2 6.22876 2 4.34315 3.17157 3.17157C4.34315 2 6.23869 2 10.0298 2C10.6358 2 11.1214 2 11.53 2.01666C11.5166 2.09659 11.5095 2.17813 11.5092 2.26057L11.5 5.09497C11.4999 6.19207 11.4998 7.16164 11.6049 7.94316C11.7188 8.79028 11.9803 9.63726 12.6716 10.3285C13.3628 11.0198 14.2098 11.2813 15.0569 11.3952C15.8385 11.5003 16.808 11.5002 17.9051 11.5001L18 11.5001H21.9574C22 12.0344 22 12.6901 22 13.5629V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22Z" fill="#409ab0"></path> </g> 
              <path d="M6 13.75C5.58579 13.75 5.25 14.0858 5.25 14.5C5.25 14.9142 5.58579 15.25 6 15.25H14C14.4142 15.25 14.75 14.9142 14.75 14.5C14.75 14.0858 14.4142 13.75 14 13.75H6Z" fill="#409ab0"></path> <path d="M6 17.25C5.58579 17.25 5.25 17.5858 5.25 18C5.25 18.4142 5.58579 18.75 6 18.75H11.5C11.9142 18.75 12.25 18.4142 12.25 18C12.25 17.5858 11.9142 17.25 11.5 17.25H6Z" fill="#409ab0"></path> 
              <path d="M11.5092 2.2601L11.5 5.0945C11.4999 6.1916 11.4998 7.16117 11.6049 7.94269C11.7188 8.78981 11.9803 9.6368 12.6716 10.3281C13.3629 11.0193 14.2098 11.2808 15.057 11.3947C15.8385 11.4998 16.808 11.4997 17.9051 11.4996L21.9574 11.4996C21.9698 11.6552 21.9786 11.821 21.9848 11.9995H22C22 11.732 22 11.5983 21.9901 11.4408C21.9335 10.5463 21.5617 9.52125 21.0315 8.79853C20.9382 8.6713 20.8743 8.59493 20.7467 8.44218C19.9542 7.49359 18.911 6.31193 18 5.49953C17.1892 4.77645 16.0787 3.98536 15.1101 3.3385C14.2781 2.78275 13.862 2.50487 13.2915 2.29834C13.1403 2.24359 12.9408 2.18311 12.7846 2.14466C12.4006 2.05013 12.0268 2.01725 11.5 2.00586L11.5092 2.2601Z" fill="#409ab0"></path> </g>
            </svg>
            <span class='tooltip rounded shadow-lg p-1 bg-gray-100'>{{file.attributes.name}} - {{file.attributes.mime}}</span>
          </div>
          
          
          <p class="line-clamp-2">
            {{file.attributes.name}} 
          </p>                
        </div>
      </div>
    </div>
    <div *ngIf="fileList == null">
      <p class="text-md">No se han cargado documentos...</p>
    </div>
  </div>
</div>