import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
    constructor(){

    }

    convertDate(date:string,timezone:string){
        return new Date(date).toLocaleString("es-CR",{timeZone: timezone, hour12: true });
    }
    convertDatefromISO(date:string){ 
      return moment(date).format();
    }
    convertDatetoISO(date:string){
      return moment.utc(date);
    }

    diferenceBetweenDatesInMinutes(startTime, endTime){
      let e = moment(endTime);
      return e.diff(startTime, 'minutes');
    }

    isinBetweendates(initial_date, final_date){
      return moment().isBetween(initial_date, final_date);
    }

    getStartAndEndOfMonth(month, year){
      const firstdate = moment().startOf('month').format('DD-MM-YYYY');
      const lastdate = moment().endOf('month').format("DD-MM-YYYY");

      return {
        firstdate, lastdate
      } 
    }
}